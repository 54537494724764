

export const cardData = [
    {
        id: 1,
        img: "/images/edWIT.png",
        category: "Executive Director",
        title: "Charleston Women In Tech",
        description: "In charge of overseeing the strategic vision of the organization and leading business decisions to drive growth in membership and impact to the community. Duties include collaborating with the Board of Directors, implementing strategic plans for the organization’s development, and managing relatonships with stakeholders.",
        linkTitle: "Learn more about CHSWIT",
        link: `https://chswomenintech.org/`
    },
    {
        id: 2,
        img: "/images/CHSWITpink.png",
        category: "Program Coordinator",
        title: "Charleston Women in Tech Mentorship Program",
        description: "In 2019, I created a mentorship program consisting of 100+ women in the Charleston area, all working in technology.  This included recruiting, program creation, pairing, plus the organization and faciliation of a formal workshop to kick-off the program.",
        linkTitle: "Learn more about CHSWIT",
        link: `https://chswomenintech.org/`
    },
    {
        id: 3,
        img: "/images/womenDevs.jpeg",
        category: "Founder",
        title: "Charleston Women Devs",
        description: "Charleston Women Devs went strong for 3+ years before being absorbed into the larger Women in Tech group in December of 2021.  We did monthly meetups; both social happy hours to network and technical presentations for skills-building. It's been a unique and wonderful experience to create a casual group of female software engineers that would rarely get to talk code with friends in our normal out-of-work settings.",
        linkTitle: "Group now in CHSWIT",
        link: `https://chswomenintech.org/`
    },


]