import React, { useState } from "react";
import { Link } from "react-router-dom";
import navItems from "../navbar/nav-items-data";

const HeaderPrimary = () => {
  const [mobileModal, setMobileModal] = useState(false);

  return (
    <>
      <div className="relative bg-white overflow-hidden">
        <div className="max-w-7xl mx-auto">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <svg
              className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>
            <div>
              {mobileModal ? (
                <div className="inset-x-0 p-2 transition transform origin-top-right md:hidden bg-green-500">
                  <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden min-h-content ">
                    <div className="px-5 pt-4 flex items-center justify-between ">
                      <div>
                        <img
                          className="h-8 w-auto"
                          src="/images/logo.svg"
                          alt=""
                        />
                      </div>
                      <div className="-mr-4">
                        <button
                          type="button"
                          onClick={() => setMobileModal(false)}
                          className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500"
                        >
                          <span className="sr-only">Close main menu</span>
                          <svg
                            className="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="px-2 pt-2 pb-3 space-y-1 min-h-full">
                      {navItems.map((item) => {
                        return (
                          <a
                            href={item.href}
                            className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                          >
                            {item.name}
                          </a>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
                  <nav
                    className="relative  flex items-center justify-between sm:h-10 lg:justify-start"
                    aria-label="Global"
                  >
                    <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                      <div className="flex items-center justify-between w-full md:w-auto">
                        <img
                          className="h-8 w-auto sm:h-10"
                          src="/images/logo.svg"
                          alt="React logo"
                        />
                        <div className="-mr-2 flex items-center md:hidden">
                          <button
                            type="button"
                            onClick={() => setMobileModal(true)}
                            className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500"
                            aria-expanded="false"
                          >
                            <span className="sr-only">Open main menu</span>
                            <svg
                              className="h-6 w-6"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M4 6h16M4 12h16M4 18h16"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8  ">
                      {navItems.map((item) => (
                        <Link to={item.href} key={item.name}>
                          <span className="font-medium text-gray-500 hover:text-gray-900">
                            {item.name}
                          </span>
                        </Link>
                      ))}
                    </div>
                  </nav>
                </div>
              )}
            </div>

            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                  <div className="flex flex-col">
                    Frontend Software Engineer
                  </div>
                  <div className="flex flex-col text-green-500 ">
                    Jenny Schutzman
                  </div>
                </h1>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  JAVASCRIPT, REACT, REDUX, ANGULAR, REST APIS, NODEJS, EXPRESS,
                  AUTH0, FETCH API, AXIOS, ISOMORPHIC FETCH, USEHTTP,
                  UI-BOOTSTRAP, MATERIAL-UI NEXT, SEMANTIC UI, I18NEXT, MYSQL,
                  MONGO DB, POUCH DB, JEST, REACT TESTING LIBRARY, MOCK SERVICE
                  WORKER, GIT/GITHUB, HTML, CSS, ATOM, INTELLIJ, VSCODE AND
                  MORE.
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-md shadow">
                    <Link to="/code">
                      <button className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-500 hover:bg-green-700 md:py-4 md:text-lg md:px-10">
                        Checkout My Code
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
            src="/images/dlake.jpg"
            alt="avalanche lake"
          />
        </div>
      </div>
    </>
  );
};

export default HeaderPrimary;
