const navItems = [
  {
    href: "/",
    name: "Home",
  },
  {
    href: "/code",
    name: "Code",
  },
  {
    href: "/tech-community",
    name: "Tech Community",
  },
  {
    href: "/former-life",
    name: "Former Life",
  },
];

export default navItems;
